<template lang="pug">
include ../../../configs/template.pug
div.row.p-1
  div.col-6
    +field-validation('body.name', 'name', '["required", "alphaUAWithSpecialCharacters"]')
  div.col-6
    +select-validation('body.languages', 'languagesList', 'language', 'labelName', '["required"]', 'true')
  div.col-12.d-flex.justify-center.mt-3
    v-btn(:loading="isLoading" color="success" @click="saveTranslateAgency") {{ $t('save') }}
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { translateAgency } from '@/mixins/validationRules'

const initBody = () => ({
  name: null,
  languages: []
})

export default {
  props: {
    actionType: { type: String, default: 'add' },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody()
    }
  },
  computed: {
    ...mapState({
      languagesList: state => state.directory.languages,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      isLoading: state => state.backoffice.isLoading
    }),
    documentID () { return this.$route.params.id }
  },
  beforeMount () {
    this.setFieldsAutocomplete()
  },
  validations () {
    return translateAgency()
  },
  methods: {
    ...mapActions(['setTranslationAgency', 'updateTranslationAgency']),

    async saveTranslateAgency () {
      if (this.$v.$invalid) return this.$v.$touch()

      const params = this.actionType === 'edit' ? { id: this.documentID, body: this.body } : this.body
      const response = await this[this.actionType === 'edit' ? 'updateTranslationAgency' : 'setTranslationAgency'](params)
      if ([200, 201].includes(response?.code)) {
        if (this.actionType === 'add') {
          this.$notification.success('notify.successNotify.addedTranslateAgency')
          this.body = initBody()
          this.$v.$reset()
        } else {
          this.$notification.success('editedDocument')
          this.$emit('loadData')
        }
      }
    },

    setFieldsAutocomplete () {
      if (this.actionType === 'edit') {
        this.body = Object.keys(this.body).reduce((t, k) => ({ ...t, [k]: this.sailorDocument[k] }), {})
      }
    }
  }
}
</script>
